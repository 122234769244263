* {
  box-sizing: border-box;
}

body {
  font-family: "Muli", sans-serif; 
  font-size: 20px;
  color: #444;
  text-align: center;
  line-height: 1.3;
  position: relative;
  min-height: 100vh;
  padding-bottom: 3.0rem;
}

nav,
footer {
  font-family: 'Ovo', serif;
  padding: 1% 0;
  background-color: rgb(255, 255, 255);
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-bottom: 2px outset black;
}

header {
  font-family: 'Ovo', serif;
  padding: 12% 0 1%;
  line-height: 0.6;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  cursor: pointer;
}

button {
  font-family: 'Ovo', serif;
  color: white;
  background-color: black;
  text-align: center;
}

section {
  box-sizing: border-box;
  text-align: left;
  background-color: #ebebeb;
  font-size: 16px;
  width: 90%;
  margin: auto;
}

img {
  width: 300px;
  height: 400px;
}

section>div {
  padding: 0.5% 3% 0.5% 3%;
}

.skills, .projects {
  text-align: center;
}

button {
  padding: 5%;
  width: 90%;
  background-color: black;
  margin: 1% 0%;
  border-color: white;
  font-size: 18px;
}

.spinner1 {
  border: 7px solid transparent;
  border-top-color: rgba(0, 0, 0, .5);
  border-bottom-color: rgba(0, 0, 0, .5);
  border-radius: 50%;
  animation: rotation 0.8s ease infinite;
  width: 100px !important;
  height: 100px !important;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 45%;
  z-index: 9999;
}
@keyframes rotation {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}