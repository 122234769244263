footer {
  height: 2.5rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 2px outset black;

  /* Baby shower style */
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContainer > p {
  font-size: px;
}

.contactIcons {
  text-decoration: none;
}

.contactIcons:hover {
  cursor: pointer;
}

ion-icon {
  font-size: 32px;
  color: black;
  cursor: pointer;
  margin: 0% 2%;
}

@media screen and (min-width: 1024px) {
  ion-icon {
    margin: 0% 0.5%;
  }
}