section {
    background-color: #ffffff !important;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4em;
}

h2 {
    margin-bottom: 0;
}