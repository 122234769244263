.main {
    background-color: #FFFFFF;
    margin: inherit auto;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    border: 1px solid lightgray;
    text-align: center;
}

p {
    line-height: 0.5em;
}

.sign {
    padding-top: 10px;
    color: #a3bbcf;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 23px;
}

.status-plus-login-img {
    height: 70px;
}

.un {
    width: 90%;
    color: rgb(63, 72, 80);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
}

.login-page-form {
    padding-top: 20px;
}

.pass {
    width: 76%;
    color: rgb(63, 72, 80);
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    background: rgba(136, 126, 126, 0.04);
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    outline: none;
    box-sizing: border-box;
    border: 2px solid rgba(0, 0, 0, 0.02);
    margin-bottom: 50px;
    margin-left: 46px;
    text-align: center;
    margin-bottom: 27px;
    font-family: 'Ubuntu', sans-serif;
}


.un:focus, .pass:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
    
}

.submit {
    cursor: pointer;
    border-radius: 5em;
    color: #fff;
    background: linear-gradient(to right, #9C27B0, #E040FB);
    border: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 13px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.04);
}

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: #a3bbcf;
    padding-top: 10px;
}

a {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    text-decoration: none
}

.back-cta {
    position: absolute;
    right: 40px;
    top: 16px;
    padding: 8px 20px;
    color: #b166a3;
    border: 2px solid #b166a3;
    border-radius: 26px;
    transition: all .2s ease;
}

.submit-btn-cont {
    text-align: center;
    padding-bottom: 20px;
}

.virtual-disclaimer {
    font-size: 10px;
}

@media only screen and (min-width: 600px) {
    .un {
        width: 70%;
    }
    #demo-submit {
        width: 70%;
    }
}

@media only screen and (min-width: 1000px) {
    .main {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-top: 100px;
    }
    .section-cont {
        display: flex;
    }

    .section-cont > div, form {
        width: 50%;
    }
    .info-div {
        padding: 0 0 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .un {
        width: 100%;
    }

    #demo-submit {
        width: 100%;
    }
}