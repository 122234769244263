.registry-page {
    height: 93vh;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.registry-link-cont {
    padding: 20px 20px;
    background-color: #FFFFFF;
    margin: inherit auto;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    border: 1px solid lightgray;
    text-align: center;
}

.logo {
    max-width: 80%;
    max-height: 100px;
    cursor: pointer;
}

.hidden {
    visibility: hidden !important;
}

.logo:hover {
    cursor: pointer;
}

#bbb-coupon {
    font-size: 10px;
}

/* .user__logout {
    display: flex;
} */
.container {
    display: inline-block;
    cursor: pointer;
}

.bar1, .bar2, .bar3 {
    width: 25px;
    height: 3px;
    background-color: rgb(73, 36, 69);
    margin: 4px 0;
    transition: 0.4s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 4px);
    transform: rotate(-45deg) translate(-6px, 4px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-5px, -5px);
    transform: rotate(45deg) translate(-5px, -5px);
}

.dropdownContent {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: -5px;
}

.dropdownContent span {
    color: black;
    padding: 12px 16px;
    display: block;
}

.logout_user_link {
    text-decoration: none;
}

.user_home_link {
    text-decoration: none;
}

@media only screen and (min-width: 1000px) {
    .registry-page {
        flex-direction: row;
    }
    .registry-link-cont {
        padding: 30px 20px;
        width: 31%;
        height: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}